import axiosInstance from '../api/axiosInstance';

export const fetchArticles = async (params = {}) => {
  const {category, rootCategory, isFeatured, isFeaturedInCategory, sort = {createdAt: 'desc'}, page = 1, limit = 10} = params;

  try {
    const response = await axiosInstance.get('/articles', {
      params: {
        category,
        rootCategory,
        isFeatured,
        isFeaturedInCategory,
        sort,
        page,
        limit,
        populate: 'author,category,coverImages'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const fetchArticle = async (categorySlug, articleSlug) => {
  try {
    const response = await axiosInstance.get(`/articles/${categorySlug}/${articleSlug}`, {
      params: {
        populate: 'author,category,coverImages,files'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const searchArticles = async (params = {}) => {
  const {query, sort = {createdAt: 'desc'}, page = 1, limit = 10} = params;

  try {
    const response = await axiosInstance.get('/articles/search', {
      params: {
        query,
        sort,
        page,
        limit,
        populate: 'author,category,coverImages'
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
