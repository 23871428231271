import React from 'react';
import MetaComponent from '../../components/common/MetaComponent';
import {Typography} from '@material-tailwind/react';

export default function Impressum() {
  const metadata = {
    title: 'Impressum - Balkanski Mozaik'
  };

  return (
    <>
      <script>
        var UC_UI_SUPPRESS_CMP_DISPLAY=true;
      </script>
      <MetaComponent meta={metadata}/>
      <div className="container mx-auto pt-10 md:pt-12 lg:pt-16 pb-2 overflow-hidden">
        <Typography variant="h1" className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 md:mb-8 lg:mb-12">
          Impressum
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          Angaben gemäß § 5 TMG
        </Typography>
        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Goran Antunovic<br/>
          Inh. Goran Antunovic<br/>
          Balkanski Mozaik<br/>
          Bruckenäcker 6<br/>
          72127 Kusterdingen
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          Kontakt
        </Typography>
        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          <strong>Telefon:</strong> +49 7071 639 8294<br/>
          <strong>E-Mail:</strong> info@balkanskimozaik.de
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          Redaktionell verantwortlich
        </Typography>
        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Goran Antunovic
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          EU-Streitschlichtung
        </Typography>
        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
          href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          Verbraucherstreitbeilegung / Universalschlichtungsstelle
        </Typography>
        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </Typography>

      </div>
    </>
  );
}
