import React from 'react';
import MetaComponent from '../../components/common/MetaComponent';
import {Typography} from '@material-tailwind/react';

export default function PrivacyPolicy() {
  const metadata = {
    title: 'Politika privatnosti - Balkanski Mozaik'
  };

  return (
    <>
      <script>
        var UC_UI_SUPPRESS_CMP_DISPLAY=true;
      </script>
      <MetaComponent meta={metadata}/>
      <div className="container mx-auto pt-10 md:pt-12 lg:pt-16 pb-2 overflow-hidden">
        <Typography variant="h1" className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 md:mb-8 lg:mb-12">
          Izjava o zaštiti podataka
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          1. Zaštita podataka ukratko
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Opće informacije
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Sljedeće informacije daju jednostavan pregled o tome što se događa s vašim osobnim podacima kada posjetite ovu
          web stranicu. Osobni podaci su svi podaci pomoću kojih vas se može osobno identificirati. Detaljne informacije
          o zaštiti podataka možete pronaći u našoj izjavi o zaštiti podataka u nastavku ovog teksta.
        </Typography>


        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Prikupljanje podataka na ovoj web stranici
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Tko je odgovoran za prikupljanje podataka na ovoj web stranici?
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Obradu podataka na ovoj web stranici provodi operater stranice. Njegove kontaktne podatke možete pronaći u
          odjeljku „Napomena o odgovornoj osobi“ u ovoj izjavi o zaštiti podataka.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Kako prikupljamo vaše podatke?
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          S jedne strane, vaši se podaci prikupljaju kada nam ih vi priopćite. To, na primjer, mogu biti podaci koje
          unosite u kontaktni obrazac.
          <br/><br/>
          Ostali podaci se automatski prikupljaju ili uz vašu suglasnost tijekom posjeta web stranici putem IT sustava.
          To su prije svega tehnički podaci (npr. internetski preglednik, operativni sustav ili vrijeme pristupa
          stranici). Ti se podaci automatski prikupljaju čim uđete na ovu web stranicu.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Za što koristimo vaše podatke?
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Dio podataka prikuplja se kako bi se osigurala besprijekorna funkcionalnost web stranice. Drugi podaci mogu se
          koristiti za analizu vašeg korisničkog ponašanja. Ako se putem web stranice sklapaju ili iniciraju ugovori,
          preneseni podaci obrađuju se za ponude ugovora, narudžbe ili druge upite.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Koja prava imate u vezi s vašim podacima?
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Imate pravo u bilo kojem trenutku besplatno dobiti informacije o porijeklu, primatelju i svrsi vaših
          pohranjenih osobnih podataka. Također imate pravo zahtijevati ispravak ili brisanje tih podataka. Ako ste dali
          privolu za obradu podataka, tu privolu možete u bilo kojem trenutku opozvati za budućnost. Osim toga, imate
          pravo zahtijevati ograničenje obrade vaših osobnih podataka pod određenim okolnostima. Također imate pravo na
          žalbu nadležnom nadzornom tijelu.
          <br/><br/>
          Za sva pitanja vezana uz zaštitu podataka možete nas kontaktirati u bilo kojem trenutku.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Analitički alati i alati trećih strana
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Kada posjetite ovu web stranicu, vaše ponašanje tijekom pregledavanja može biti statistički analizirano. To se
          prije svega radi pomoću tzv. analitičkih programa.
          <br/><br/>
          Detaljne informacije o tim analitičkim programima nalaze se u sljedećoj izjavi o zaštiti podataka.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          2. Hosting
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Web stranica je hostirana kod sljedećeg pružatelja usluga:
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Amazon Web Services (AWS)
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Pružatelj usluga je Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luksemburg (u daljnjem
          tekstu AWS).
          <br/><br/>
          Kada posjetite našu web stranicu, vaši osobni podaci obrađuju se na poslužiteljima AWS-a. Pri tome se osobni
          podaci mogu prenijeti i matičnoj tvrtki AWS-a u SAD. Prijenos podataka u SAD temelji se na standardnim
          ugovornim klauzulama EU. Pojedinosti možete pronaći ovdje:
          <a
            href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/">https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/</a>.
          <br/><br/>
          Dodatne informacije možete pronaći u AWS-ovoj izjavi o zaštiti podataka: <a
          href="https://aws.amazon.com/de/privacy/?nc1=f_pr">https://aws.amazon.com/de/privacy/?nc1=f_pr</a>.
          <br/><br/>
          Korištenje AWS-a temelji se na članku 6. stavku 1. točki (f) GDPR-a. Imamo legitiman interes za što pouzdaniji
          prikaz naše web stranice. Ako je zatražena odgovarajuća privola, obrada se temelji isključivo na članku 6.
          stavku 1. točki (a) GDPR-a i § 25. stavku 1. TDDDG-a, u mjeri u kojoj privola obuhvaća pohranu kolačića ili
          pristup informacijama na korisničkom uređaju (npr. prepoznavanje uređaja). Privola se može opozvati u bilo
          kojem trenutku.
          <br/><br/>
          AWS je certificiran prema "EU-SAD Okviru privatnosti podataka" (DPF). DPF je sporazum između Europske unije i
          SAD-a koji osigurava usklađenost s europskim standardima zaštite podataka pri obradi podataka u SAD-u. Svaka
          tvrtka certificirana prema DPF-u obvezuje se pridržavati ovih standarda zaštite podataka. Dodatne informacije
          o tome možete pronaći ovdje: <a
          href="https://www.dataprivacyframework.gov/participant/5776">https://www.dataprivacyframework.gov/participant/5776</a>.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Ugovor o obradi podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Sklopili smo ugovor o obradi podataka (AVV) za korištenje gore navedene usluge. To je ugovor propisan zakonima
          o zaštiti podataka, koji osigurava da AWS obrađuje osobne podatke naših posjetitelja web stranice samo prema
          našim uputama i u skladu s GDPR-om.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          3. Opće informacije i obavezne obavijesti
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Zaštita podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Operateri ovih stranica vrlo ozbiljno shvaćaju zaštitu vaših osobnih podataka. Vaše osobne podatke obrađujemo
          povjerljivo i u skladu sa zakonskim propisima o zaštiti podataka te ovom Izjavom o zaštiti podataka.
          <br/><br/>
          Kada koristite ovu web stranicu, prikupljaju se različiti osobni podaci. Osobni podaci su podaci pomoću kojih
          se možete osobno identificirati. Ova Izjava o zaštiti podataka objašnjava koje podatke prikupljamo i za što ih
          koristimo. Također objašnjava kako i u koju svrhu to radimo.
          <br/><br/>
          Napominjemo da prijenos podataka putem interneta (npr. kod komunikacije putem e-maila) može imati sigurnosne
          slabosti. Potpuna zaštita podataka od pristupa trećih strana nije moguća.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Napomena o odgovornoj osobi
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Odgovorna osoba za obradu podataka na ovoj web stranici je:
          <br/><br/>
          Goran Antunović <br/>
          Inh. Goran Antunović <br/>
          Balkanski Mozaik <br/>
          Bruckenäcker 6 <br/>
          72127 Kusterdingen
          <br/><br/>
          Telefon: +49 7071 639 8294 <br/>
          E-mail: info@balkanskimozaik.de
          <br/><br/>
          Odgovorna osoba je fizička ili pravna osoba koja sama ili zajedno s drugima odlučuje o svrsi i sredstvima
          obrade osobnih podataka (npr. imena, adrese e-pošte i sl.).
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Razdoblje pohrane
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ako u ovoj Izjavi o zaštiti podataka nije navedeno specifično razdoblje pohrane, vaši osobni podaci ostat će
          kod nas sve dok svrha obrade ne prestane. Ako podnesete zahtjev za brisanje podataka ili povučete svoju
          privolu za obradu podataka, vaši će podaci biti izbrisani osim ako nemamo druge zakonski dopuštene razloge za
          njihovu pohranu (npr. porezni ili računovodstveni rokovi čuvanja); u tom slučaju brisanje se obavlja nakon
          prestanka tih razloga.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Opće napomene o pravnim osnovama za obradu podataka na ovoj web stranici
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ako ste dali privolu za obradu podataka, obrađujemo vaše osobne podatke na temelju članka 6. stavka 1. točke
          (a) GDPR-a ili članka 9. stavka 2. točke (a) GDPR-a, ako se obrađuju posebne kategorije podataka prema članku
          9. stavku 1. GDPR-a. Ako ste izričito pristali na prijenos osobnih podataka u treće zemlje, obrada podataka
          također se temelji na članku 49. stavku 1. točki (a) GDPR-a. Ako je obrada podataka potrebna za izvršenje
          ugovora ili za provedbu predugovornih mjera, vaši se podaci obrađuju na temelju članka 6. stavka 1. točke (b)
          GDPR-a. Nadalje, vaši se podaci obrađuju ako je to potrebno za ispunjenje pravne obveze na temelju članka 6.
          stavka 1. točke (c) GDPR-a. Obrada podataka može se također temeljiti na našem legitimnom interesu prema
          članku 6. stavku 1. točki (f) GDPR-a. O relevantnim pravnim osnovama za svaki pojedini slučaj bit ćete
          obaviješteni u sljedećim odjeljcima ove Izjave o zaštiti podataka.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Službenik za zaštitu podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Imenovali smo službenika za zaštitu podataka.
          <br/><br/>
          <b>Goran Antunović</b><br/>
          Bruckenäcker 6 <br/>
          72127 Kusterdingen
          <br/><br/>
          Telefon: +49 7071 639 8294 <br/>
          E-mail: info@balkanskimozaik.de
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Primatelji osobnih podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          U okviru našeg poslovanja surađujemo s raznim vanjskim subjektima. Povremeno je potrebno prenijeti osobne
          podatke tim vanjskim subjektima. Osobne podatke prenosimo trećim stranama samo kada je to potrebno za
          izvršenje ugovora, kada smo zakonski obvezni (npr. prijenos podataka poreznim vlastima), kada imamo legitiman
          interes prema članku 6. stavku 1. točki (f) GDPR-a ili kada druga pravna osnova omogućuje prijenos podataka.
          Ako koristimo izvršitelje obrade, prenosimo osobne podatke naših korisnika samo na temelju važećeg ugovora o
          obradi podataka. U slučaju zajedničke obrade, sklopljen je ugovor o zajedničkoj obradi podataka.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Povlačenje vaše privole za obradu podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Mnogi procesi obrade podataka mogući su samo uz vašu izričitu privolu. Već dana privola može se povući u bilo
          kojem trenutku. Zakonitost obrade podataka provedene do trenutka povlačenja ostaje nepromijenjena.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Pravo na prigovor na obradu podataka u posebnim slučajevima te na izravni marketing (članak 21. GDPR-a)
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          AKO SE OBRADA PODATAKA TEMELJI NA ČLANKU 6. STAVKU 1. TOČKI (E) ILI (F) GDPR-A, IMATE PRAVO U BILO KOJEM
          TRENUTKU PRIGOVORITI NA OBRADU VAŠIH OSOBNIH PODATAKA IZ RAZLOGA KOJI SE ODNOSE NA VAŠU SPECIFIČNU SITUACIJU;
          OVO UKLJUČUJE I PROFILIRANJE NA TEMELJU TIH ODREDBI. ODGOVARAJUĆE PRAVNE OSNOVE NA KOJIMA SE TEMELJI OBRADA
          MOŽETE PRONAĆI U OVOJ IZJAVI O ZAŠTITI PODATAKA. AKO PODNESETE PRIGOVOR, NEĆEMO VIŠE OBRAĐIVATI VAŠE OSOBNE
          PODATKE, OSIM AKO MOŽEMO DOKAZATI NEOPHODNE I LEGITIMNE RAZLOGE ZA OBRADU KOJI NADILAZE VAŠE INTERESE, PRAVA I
          SLOBODE ILI AKO OBRADA SLUŽI ZA USPOSTAVU, OSTVARIVANJE ILI OBRANU PRAVNIH ZAHTJEVA (PRIGOVOR PREMA ČLANKU 21.
          STAVKU 1. GDPR-A).
          <br/><br/>
          AKO SE VAŠI OSOBNI PODACI OBRAĐUJU U SVRHU IZRAVNOG MARKETINGA, IMATE PRAVO U BILO KOJEM TRENUTKU PRIGOVORITI
          NA OBRADU VAŠIH OSOBNIH PODATAKA U TU SVRHU; OVO UKLJUČUJE I PROFILIRANJE U VEZI S TAKVIM IZRAVNIM
          MARKETINGOM. AKO PRIGOVORITE, VAŠI OSOBNI PODACI VIŠE SE NEĆE KORISTITI U SVRHU IZRAVNOG MARKETINGA (PRIGOVOR
          PREMA ČLANKU 21. STAVKU 2. GDPR-A).
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Pravo na podnošenje pritužbe nadzornom tijelu
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ako dođe do kršenja GDPR-a, imate pravo podnijeti pritužbu nadzornom tijelu, osobito u državi članici u kojoj
          imate uobičajeno boravište, radno mjesto ili mjesto navodnog kršenja. Pravo na pritužbu postoji neovisno o
          drugim administrativnim ili pravnim sredstvima.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Pravo na prijenos podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Imate pravo zatražiti da se podaci koje obrađujemo automatiziranim putem na temelju vaše privole ili u svrhu
          izvršenja ugovora predaju vama ili trećoj strani u standardnom, strojno čitljivom formatu. Ako zahtijevate
          izravan prijenos podataka drugom voditelju obrade, to će se učiniti samo ako je tehnički izvedivo.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Pravo na pristup, ispravak i brisanje
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          U okviru važećih zakonskih odredbi, imate pravo u bilo kojem trenutku besplatno zatražiti informacije o vašim
          pohranjenim osobnim podacima, njihovom porijeklu, primatelju i svrsi obrade te, prema potrebi, pravo na
          ispravak ili brisanje tih podataka. Za dodatne informacije o osobnim podacima možete nas kontaktirati u bilo
          kojem trenutku.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Pravo na ograničenje obrade
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Imate pravo zahtijevati ograničenje obrade vaših osobnih podataka. Možete nas kontaktirati u vezi s tim u bilo
          kojem trenutku. Pravo na ograničenje obrade postoji u sljedećim slučajevima:
          <br/><br/>
          <ul>
            <li>
              Ako osporavate točnost vaših osobnih podataka pohranjenih kod nas, obično nam je potrebno vrijeme da to
              provjerimo. Za vrijeme trajanja provjere imate pravo zahtijevati ograničenje obrade vaših osobnih
              podataka.
            </li>
            <li>
              Ako se obrada vaših osobnih podataka provodi nezakonito, umjesto brisanja možete zahtijevati ograničenje
              obrade podataka.
            </li>
            <li>
              Ako nam vaši osobni podaci više nisu potrebni, ali su vam potrebni za ostvarivanje, obranu ili
              postavljanje pravnih zahtjeva, imate pravo umjesto brisanja zahtijevati ograničenje obrade vaših osobnih
              podataka.
            </li>
            <li>
              Ako ste podnijeli prigovor prema članku 21. stavku 1. GDPR-a, mora se provesti usklađivanje vaših i naših
              interesa. Dok se ne utvrdi čiji interesi prevladavaju, imate pravo zahtijevati ograničenje obrade vaših
              osobnih podataka.
            </li>
          </ul>
          <br/><br/>
          Ako ste ograničili obradu vaših osobnih podataka, ti se podaci mogu obrađivati – osim njihove pohrane – samo
          uz vašu privolu ili u svrhu postavljanja, ostvarivanja ili obrane pravnih zahtjeva ili zaštite prava druge
          fizičke ili pravne osobe ili iz razloga važnog javnog interesa Europske unije ili države članice.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          SSL i TLS enkripcija
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova stranica koristi SSL ili TLS enkripciju iz sigurnosnih razloga i radi zaštite prijenosa povjerljivih
          sadržaja, poput narudžbi ili upita koje šaljete nama kao operateru stranice. Šifriranu vezu možete prepoznati
          po tome što se adresa preglednika mijenja iz "http://" u "https://" i po simbolu lokota u adresnoj traci vašeg
          preglednika.
          <br/><br/>
          Ako je SSL ili TLS enkripcija aktivirana, podaci koje nam prenosite ne mogu biti pročitani od strane trećih
          osoba.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          4. Prikupljanje podataka na ovoj web stranici
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Kolačići
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Naše internetske stranice koriste takozvane "kolačiće". Kolačići su male tekstualne datoteke koje ne uzrokuju
          nikakvu štetu na vašem uređaju. Oni se ili privremeno pohranjuju za vrijeme trajanja sesije (session kolačići)
          ili trajno (trajni kolačići) na vašem uređaju. Session kolačići automatski se brišu nakon vašeg posjeta.
          Trajni kolačići ostaju pohranjeni na vašem uređaju sve dok ih ne izbrišete sami ili dok ih vaš web preglednik
          automatski ne izbriše.
          <br/><br/>
          Kolačići mogu dolaziti od nas (prvostrani kolačići) ili od trećih strana (treće strane kolačići). Treće strane
          kolačići omogućuju uključivanje određenih usluga trećih strana unutar web stranice (npr. kolačići za obradu
          usluga plaćanja).
          <br/><br/>
          Kolačići imaju različite funkcije. Mnogi kolačići su tehnički potrebni jer određene funkcije web stranica ne
          bi radile bez njih (npr. funkcija košarice ili prikaz videa). Drugi kolačići koriste se za analizu korisničkog
          ponašanja ili u marketinške svrhe.
          <br/><br/>
          Kolačići koji su potrebni za elektroničku komunikaciju, za pružanje određenih funkcija koje želite (npr.
          funkcija košarice) ili za optimizaciju web stranice (npr. kolačići za mjerenje web prometa) pohranjuju se na
          temelju članka 6. stavka 1. točke (f) GDPR-a, osim ako nije navedena druga pravna osnova. Operater web
          stranice ima legitiman interes za pohranu potrebnih kolačića radi tehnički ispravnog i optimiziranog pružanja
          svojih usluga. Ako je zatražena privola za pohranu kolačića i sličnih tehnologija, obrada se temelji
          isključivo na toj privoli (članak 6. stavak 1. točka (a) GDPR-a i § 25. stavak 1. TDDDG-a); privola se može
          povući u bilo kojem trenutku.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Možete konfigurirati postavke vašeg preglednika
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Postavke vašeg preglednika možete prilagoditi tako da budete informirani o postavljanju kolačića te da
          kolačiće dopuštate samo u pojedinačnim slučajevima, isključite prihvaćanje kolačića za određene slučajeve ili
          općenito te omogućite automatsko brisanje kolačića pri zatvaranju preglednika. Ako onemogućite kolačiće,
          funkcionalnost ove web stranice može biti ograničena.
          <br/><br/>
          Koji kolačići i usluge se koriste na ovoj web stranici, možete saznati u ovoj Izjavi o zaštiti podataka.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Privola uz pomoć Usercentricsa
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova web stranica koristi tehnologiju za prikupljanje privole od strane Usercentrics-a kako bi prikupila vašu
          privolu za pohranu određenih kolačića na vaš uređaj ili korištenje određenih tehnologija te ih dokumentirala u
          skladu sa zakonima o zaštiti podataka. Pružatelj ove usluge je Usercentrics GmbH, Sendlinger Straße 7, 80331
          München, web stranica: <a href="https://usercentrics.com/de/">https://usercentrics.com/de/</a> (u daljnjem
          tekstu "Usercentrics").
          <br/><br/>
          Kada posjetite našu web stranicu, sljedeći osobni podaci prenose se Usercentrics-u: <br/>
          <ul>
            <li>
              Vaša privola ili opoziv privole
            </li>
            <li>
              Vaša IP adresa
            </li>
            <li>
              Informacije o vašem pregledniku
            </li>
            <li>
              Informacije o vašem uređaju
            </li>
            <li>
              Vrijeme vašeg posjeta web stranici
            </li>
            <li>
              Geolokacija
            </li>
          </ul>
          <br/>
          Usercentrics također pohranjuje kolačić u vašem pregledniku kako bi mogao povezati dane privole ili njihove
          opozive s vašim korisničkim uređajem. Podaci se pohranjuju dok nas ne zatražite njihovo brisanje, dok sami ne
          izbrišete kolačić Usercentrics ili dok svrha pohrane ne prestane. Obavezni zakonski rokovi pohrane ostaju
          nepromijenjeni.
          <br/><br/>
          Primjena Usercentrics tehnologije provodi se radi osiguravanja zakonski propisanih privola za korištenje
          određenih tehnologija. Pravna osnova za to je članak 6. stavak 1. točka (c) GDPR-a.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Ugovor o obradi podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Sklopili smo ugovor o obradi podataka s Usercentrics-om. Ovo je ugovor propisan zakonima o zaštiti podataka,
          koji osigurava da Usercentrics obrađuje osobne podatke naših korisnika samo prema našim uputama i u skladu s
          GDPR-om.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          5. Društvene mreže
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Facebook
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Na našoj web stranici integrirani su elementi društvene mreže Facebook. Pružatelj usluge je Meta Platforms
          Ireland Limited, Merrion Road, Dublin 4, D04 X2K5, Irska. Prema Facebookovim izjavama, prikupljeni podaci se
          također prenose u SAD i druge treće zemlje.
          <br/><br/>
          Pregled elemenata društvenih mreža Facebook možete pronaći ovdje: <a
          href="https://developers.facebook.com/docs/plugins/?locale=de_DE">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>.
          <br/><br/>
          Ako je aktivan društveni element, uspostavlja se izravna veza između vašeg uređaja i Facebook poslužitelja.
          Facebook time dobiva informaciju da ste posjetili ovu web stranicu sa svojom IP adresom. Ako kliknete na
          Facebook "Like" gumb dok ste prijavljeni na svoj Facebook račun, sadržaj ove web stranice možete povezati sa
          svojim Facebook profilom. Facebook time može povezati posjet ovoj web stranici s vašim korisničkim računom.
          Napominjemo da mi, kao operater ove web stranice, nemamo saznanja o sadržaju prenesenih podataka niti o
          njihovoj upotrebi od strane Facebooka.
          <br/><br/>
          Dodatne informacije možete pronaći u Facebookovoj izjavi o zaštiti podataka na: <a
          href="https://de-de.facebook.com/privacy/explanation">https://de-de.facebook.com/privacy/explanation</a>.
          <br/><br/>
          <b>Korištenje ove usluge temelji se na vašoj privoli prema članku 6. stavku 1. točki (a) GDPR-a i § 25. stavku
            1. TDDDG-a. Privolu možete povući u bilo kojem trenutku.</b>
          <br/><br/>
          Ako se putem ovdje opisanog alata prikupljaju osobni podaci na našoj web stranici i prosljeđuju Facebooku, mi
          i Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irska, zajednički smo
          odgovorni za ovu obradu podataka (članak 26. GDPR-a). Zajednička odgovornost ograničena je isključivo na
          prikupljanje podataka i njihovo prosljeđivanje Facebooku. Obrada koja slijedi nakon prosljeđivanja podataka
          nije dio zajedničke odgovornosti. Naše zajedničke obveze regulirane su sporazumom o zajedničkoj obradi
          podataka. Tekst sporazuma možete pronaći ovdje: <a
          href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a>.
          <br/><br/>
          Prema ovom sporazumu, odgovorni smo za pružanje informacija o zaštiti podataka prilikom korištenja Facebook
          alata te za sigurnu integraciju alata na našoj web stranici. Facebook je odgovoran za sigurnost podataka
          svojih proizvoda. Prava ispitanika (npr. zahtjevi za pristup) vezano za podatke obrađene od strane Facebooka
          možete ostvarivati izravno putem Facebooka. Ako se obratite nama, proslijedit ćemo vaš zahtjev Facebooku.
          <br/><br/>
          Prijenos podataka u SAD temelji se na standardnim ugovornim klauzulama Europske komisije. Detalje možete
          pronaći ovdje: <br/>
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum">https://www.facebook.com/legal/EU_data_transfer_addendum</a>,
          <a
            href="https://de-de.facebook.com/help/566994660333381">https://de-de.facebook.com/help/566994660333381</a> i
          <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>.
          <br/><br/>
          Facebook je certificiran prema "EU-SAD Okviru privatnosti podataka" (DPF). To je sporazum između Europske
          unije i SAD-a koji osigurava usklađenost s europskim standardima zaštite podataka pri obradi podataka u SAD-u.
          Više informacija možete pronaći ovdje: <a
          href="https://www.dataprivacyframework.gov/participant/4452">https://www.dataprivacyframework.gov/participant/4452</a>.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          X (bivši Twitter)
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Na ovoj web stranici integrirane su funkcije usluge X (bivši Twitter). Te funkcije pruža matična tvrtka X
          Corp., 1355 Market Street, Suite 900, San Francisco, CA 94103, SAD. Za korisnike izvan SAD-a odgovorna je
          podružnica Twitter International Unlimited Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07,
          Irska.
          <br/><br/>
          Ako je aktiviran društveni element, uspostavlja se izravna veza između vašeg uređaja i X poslužitelja. X
          (bivši Twitter) time prima informacije o vašem posjetu ovoj web stranici. Koristeći funkcije X (npr.
          "Re-Tweet" ili "Repost"), web stranice koje ste posjetili povezuju se s vašim X računom i dijele s drugim
          korisnicima.
          <br/><br/>
          Napominjemo da mi, kao operateri ove web stranice, nemamo saznanja o sadržaju prenesenih podataka niti o
          njihovoj uporabi od strane X. Dodatne informacije možete pronaći u izjavi o zaštiti podataka od strane X:
          <a href="https://x.com/de/privacy">https://x.com/de/privacy</a>.
          <br/><br/>
          Korištenje ove usluge temelji se na vašoj privoli prema članku 6. stavku 1. točki (a) GDPR-a i § 25. stavku 1.
          TDDDG-a. Privolu možete povući u bilo kojem trenutku.
          <br/><br/>
          Prijenos podataka u SAD temelji se na standardnim ugovornim klauzulama Europske komisije. Detalje možete
          pronaći ovdje: <a
          href="https://gdpr.x.com/en/controller-to-controller-transfers.html">https://gdpr.x.com/en/controller-to-controller-transfers.html</a>.
          <br/><br/>
          Svoje postavke privatnosti na X možete promijeniti ovdje: <a
          href="https://x.com/settings/account">https://x.com/settings/account</a>.
          <br/><br/>
          Tvrtka X je također certificirana prema "EU-SAD Okviru privatnosti podataka" (DPF). Više informacija možete
          pronaći ovdje: <a
          href="https://www.dataprivacyframework.gov/participant/2710">https://www.dataprivacyframework.gov/participant/2710</a>.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          6. Alati za analizu i oglašavanje
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Google Analytics
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova web stranica koristi funkcije usluge za web analizu Google Analytics. Pružatelj usluge je Google Ireland
          Limited („Google”), Gordon House, Barrow Street, Dublin 4, Irska.
          <br/><br/>
          Google Analytics omogućuje operateru web stranice analizu ponašanja posjetitelja stranice. Pritom operater web
          stranice dobiva različite podatke o korištenju, kao što su pregledi stranica, trajanje posjeta, korišteni
          operativni sustavi i porijeklo korisnika. Ti podaci mogu biti objedinjeni u korisnički profil i pridruženi
          odgovarajućem uređaju korisnika.
          <br/><br/>
          Google Analytics također bilježi vaše kretanje mišem i klikove te koristi razne modele za predikciju kako bi
          popunio podatkovne praznine i primijenio tehnologije strojnog učenja za analizu podataka.
          <br/><br/>
          Google Analytics koristi tehnologije koje omogućuju prepoznavanje korisnika radi analize ponašanja (npr.
          kolačići ili prepoznavanje uređaja). Informacije koje prikuplja Google o korištenju ove web stranice obično se
          prenose na Googleov poslužitelj u SAD-u i tamo pohranjuju.
          <br/><br/>
          Korištenje ove usluge temelji se na vašoj privoli prema članku 6. stavku 1. točki (a) GDPR-a i § 25. stavku 1.
          TDDDG-a. Privolu možete povući u bilo kojem trenutku.
          <br/><br/>
          Prijenos podataka u SAD temelji se na standardnim ugovornim klauzulama Europske komisije. Detalje možete
          pronaći ovdje: <a
          href="https://privacy.google.com/businesses/controllerterms/mccs/">https://privacy.google.com/businesses/controllerterms/mccs/</a>.
          <br/><br/>
          Tvrtka Google certificirana je prema „EU-SAD Okviru privatnosti podataka“ (DPF). Više informacija možete
          pronaći ovdje: <a
          href="https://www.dataprivacyframework.gov/participant/5780">https://www.dataprivacyframework.gov/participant/5780</a>.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Anonimizacija IP adrese
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Na ovoj web stranici aktivirana je funkcija anonimizacije IP adrese. To znači da će Google skratiti vašu IP
          adresu unutar država članica Europske unije ili u drugim državama koje su potpisale Sporazum o Europskom
          gospodarskom prostoru prije prijenosa u SAD. Samo u iznimnim slučajevima puna IP adresa bit će prenesena na
          Googleov poslužitelj u SAD-u i tamo skraćena. U ime operatera ove web stranice, Google koristi ove podatke za
          analizu vašeg korištenja web stranice, izradu izvještaja o aktivnostima na web stranici i pružanje drugih
          usluga povezanih s korištenjem web stranice i interneta operateru web stranice. IP adresa koju vaš preglednik
          prenosi u sklopu Google Analyticsa neće biti spojena s drugim Googleovim podacima.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Dodatak za preglednik
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Možete spriječiti Google u prikupljanju i obradi vaših podataka tako što ćete preuzeti i instalirati dodatak
          za preglednik koji je dostupan na sljedećem linku:
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
          <br/><br/>
          Više informacija o postupanju s korisničkim podacima u Google Analytics možete pronaći u Googleovoj izjavi o
          zaštiti podataka:
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Google Signali
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Koristimo Google Signale. Kada posjetite našu web stranicu, Google Analytics prikuplja podatke poput vaše
          lokacije, povijesti pretraživanja, povijesti na YouTubeu te demografskih podataka (podaci o posjetiteljima).
          Ovi podaci mogu se koristiti za personalizirano oglašavanje putem Google Signala. Ako imate Google račun,
          podaci Google Signala bit će povezani s vašim računom te korišteni za personalizirane reklamne poruke. Podaci
          se također koriste za izradu anonimnih statistika o ponašanju korisnika.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Ugovor o obradi podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Sklopili smo ugovor o obradi podataka s Googleom i u potpunosti primjenjujemo stroge zahtjeve njemačkih tijela
          za zaštitu podataka pri korištenju Google Analyticsa.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          E-Commerce mjerenje pomoću Google Analytics
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova web stranica koristi funkciju „E-Commerce mjerenja“ unutar Google Analyticsa. Ova funkcija omogućuje
          praćenje ponašanja korisnika prilikom kupnje kako bi se poboljšale marketinške strategije. Podaci poput
          narudžbi, prosječne vrijednosti narudžbe, troškova dostave i vremena potrebnog za kupnju mogu biti prikupljeni
          i analizirani.
        </Typography>

        <Typography variant="h2" className="text-xl md:text-2xl font-semibold mb-2">
          7. Dodaci i alati
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          YouTube s proširenom zaštitom podataka
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova web stranica koristi dodatke s web stranice YouTube. Operater stranice je Google Ireland Limited
          („Google”), Gordon House, Barrow Street, Dublin 4, Irska.
          <br/><br/>
          Kada posjetite neku od naših stranica koja sadrži YouTube dodatak, uspostavlja se veza s YouTube
          poslužiteljima. Pritom se poslužitelju YouTubea prenosi informacija o tome koje ste stranice posjetili.
          <br/><br/>
          Ako ste prijavljeni na svoj YouTube račun, omogućujete YouTubeu da vaše aktivnosti povezane s pregledavanjem
          izravno poveže s vašim osobnim profilom. To možete spriječiti tako da se odjavite sa svog YouTube računa.
          <br/><br/>
          Koristimo YouTube u proširenom načinu zaštite podataka. Prema navodima YouTubea, videozapisi reproducirani u
          proširenom načinu zaštite podataka ne koriste se za personalizaciju korisničkog ponašanja na YouTubeu. Ipak,
          YouTube pohranjuje informacije o korisnicima čak i ako ne gledate videozapis. Ove informacije uključuju,
          primjerice, vašu IP adresu i podatke o pregledniku.
          <br/><br/>
          Korištenje YouTubea temelji se na članku 6. stavku 1. točki (f) GDPR-a. Imamo legitiman interes za atraktivnu
          prezentaciju naših online ponuda. Ako je zatražena odgovarajuća privola, obrada se temelji isključivo na
          članku 6. stavku 1. točki (a) GDPR-a i § 25. stavku 1. TDDDG-a, u mjeri u kojoj privola uključuje pohranu
          kolačića ili pristup informacijama na korisničkom uređaju (npr. prepoznavanje uređaja). Privolu možete povući
          u bilo kojem trenutku.
          <br/><br/>
          Više informacija o zaštiti podataka na YouTubeu možete pronaći u njihovoj izjavi o zaštiti podataka na
          sljedećem linku: <a
          href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Google Fonts
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova stranica koristi Google Fonts za ujednačen prikaz fontova. Pružatelj usluge je Google Ireland Limited
          („Google”), Gordon House, Barrow Street, Dublin 4, Irska.
          <br/><br/>
          Prilikom posjete našoj stranici, vaš preglednik preuzima potrebne fontove s Googleovih poslužitelja kako bi
          pravilno prikazao tekstove i fontove. U tu svrhu, preglednik koji koristite mora se povezati s Googleovim
          poslužiteljima. Time Google saznaje da je vaša IP adresa pristupila našoj web stranici.
          <br/><br/>
          Korištenje Google Fontsa temelji se na članku 6. stavku 1. točki (f) GDPR-a. Imamo legitiman interes za
          ujednačen i atraktivan prikaz naše web stranice. Ako je zatražena odgovarajuća privola, obrada se temelji
          isključivo na članku 6. stavku 1. točki (a) GDPR-a i § 25. stavku 1. TDDDG-a. Privolu možete povući u bilo
          kojem trenutku.
          <br/><br/>
          Ako vaš preglednik ne podržava Google Fonts, koristi se standardni font s vašeg računala.
          <br/><br/>
          Više informacija o Google Fontsu možete pronaći ovdje: <br/>
          <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> i
          <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.
        </Typography>

        <Typography variant="h3" className="text-lg md:text-xl font-semibold mb-2">
          Font Awesome (lokalno hostiranje)
        </Typography>

        <Typography variant="paragraph" className="mb-6 md:mb-8 lg:mb-12">
          Ova stranica koristi Font Awesome za dosljedan prikaz fontova. Font Awesome je instaliran lokalno, što znači
          da se ne uspostavlja veza s poslužiteljima Fonticons, Inc.
          <br/><br/>
          Više informacija o zaštiti podataka u vezi s Font Awesome možete pronaći ovdje: <a
          href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a>.
        </Typography>
      </div>
    </>
  );
}
