import React, {useEffect, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useInView} from 'react-intersection-observer';
import ArticleCard, {ArticleCardSkeleton} from '../article/ArticleCard';

const ArticlesGrid = ({fetchArticles, fetchArticleArgs}) => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const {ref, inView} = useInView({
    triggerOnce: false,
    threshold: 0.1
  });

  const loadArticles = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchArticles({...fetchArticleArgs, page});
      setArticles((prev) => [...prev, ...data.docs]);
      setHasMore(data.hasNextPage);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }, [fetchArticles, fetchArticleArgs, page]);

  useEffect(() => {
    setPage(1);
    setArticles([]);
    setHasMore(true);
  }, [fetchArticleArgs]);

  useEffect(() => {
    loadArticles();
  }, [loadArticles, page]);

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, isLoading, hasMore]);

  const articleList = useMemo(() => (
    <div className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-2.5 xl:gap-4">
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article}/>
      ))}
    </div>
  ), [articles]);

  const skeletonList = useMemo(() => (
    <div
      className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 lg:gap-2.5 xl:gap-4 animate-pulse">
      <ArticleCardSkeleton/>
      <ArticleCardSkeleton/>
      <ArticleCardSkeleton/>
      <ArticleCardSkeleton/>
      <ArticleCardSkeleton/>
    </div>
  ), []);

  return (
    <div>
      {articleList}
      {isLoading && skeletonList}
      <div ref={ref}></div>
    </div>
  );
};

ArticlesGrid.propTypes = {
  fetchArticles: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired
};

export default ArticlesGrid;
