import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Collapse, IconButton, Navbar as MaterialNavbar, Typography} from '@material-tailwind/react';
import {Bars3Icon, XMarkIcon, MagnifyingGlassIcon} from '@heroicons/react/24/outline';
import {useSelector} from 'react-redux';
import SearchInput from '../search-input/SearchInput';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const NavList = ({onItemClick}) => {
  const {categories} = useSelector((state) => state.categories);
  const [openSubmenus, setOpenSubmenus] = useState({});

  const handleOnItemClicked = () => {
    if (onItemClick) {
      onItemClick();
    }
  };

  const toggleSubmenu = (slug) => {
    setOpenSubmenus((prev) => ({
      ...prev,
      [slug]: !prev[slug],
    }));
  };

  return (
    <ul className="my-2.5 px-2.5 xl:px-0 flex flex-col gap-x-8 gap-y-2.5 md:flex-row">
      {
        categories.map(category => (
          <Typography
            as="li"
            variant="paragraph"
            color="gray"
            className="font-semibold xl:relative group"
            key={category.slug}
          >
            <span className={'flex items-center'}>
               <Link to={`/${category.slug}`}
                     className={`flex items-center transition-colors`}
                     onClick={handleOnItemClicked}>
                {category.name}
            </Link>

              {category.children && category.children.length > 0 && (
                  <button
                      type="button"
                      className="xl:hidden ml-2"
                      onClick={() => toggleSubmenu(category.slug)}
                  >
                    {openSubmenus[category.slug] ? (
                        <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                        <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
              )}
            </span>

            {category.children && category.children.length > 0 && openSubmenus[category.slug] && (
                <ul className="xl:hidden pl-4 mt-2 space-y-2">
                  {category.children.map((child) => (
                      <li key={child.slug}>
                        <Link
                            to={`/${child.slug}`}
                            className=" font-semibold text-base leading-relaxed"
                            onClick={handleOnItemClicked}
                        >
                          {child.name}
                        </Link>
                      </li>
                  ))}
                </ul>
            )}

            {category.children && category.children.length > 0 && (
              <ul
                className="hidden xl:block absolute left-0 top-full p-2 w-72 bg-white border border-gray-200 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none transition-all duration-200"
                >
                {category.children.map((child) => (
                  <li key={child.slug}>
                    <Link
                      to={`/${child.slug}`}
                      className="block rounded px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      onClick={handleOnItemClicked}
                    >
                      {child.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            </Typography>
      ))}
    </ul>
  );
};

const Navbar = (props) => {
  const [openNav, setOpenNav] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 1140 && setOpenNav(false);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const closeNavbar = () => {
    setOpenNav(false);
    setSearchOpen(false);
  };

  const openSearch = () => {
    setSearchOpen(true);
  };

  const openNavbar = () => {
    setOpenNav(true);
  };

  return (
    <MaterialNavbar
      className={`mx-auto relative py-0 px-0 bg-white max-w-full bg-opacity-100 border-0 w-screen border-b shadow-md rounded-none ${props.className}`}>
      <div
        className="flex py-3 px-2 md:px-4 lg:px-0 items-center max-w-screen-2xl md:container justify-between gap-10 mx-auto text-gray-900">
        <Link to={'/'} className={'opacity-75'}>
          <img src="/images/logo.svg" className={'w-28 md:w-36'} alt="logo"/>
        </Link>
        <div className="hidden xl:block">
          <NavList/>
        </div>
        <div className="hidden xl:flex items-center">
          <SearchInput/>
        </div>
        <span className={'flex gap-6 xl:hidden'}>
          {!openNav && !searchOpen && <IconButton
              variant="text"
              className="flex w-6 h-6 justify-center items-center text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent"
              ripple={false}
              onClick={openSearch}
          >
              <MagnifyingGlassIcon  className="h-6 w-6" strokeWidth={2}/>
          </IconButton>
          }
          {
              !openNav && !searchOpen && <IconButton
                variant="text"
                className="flex w-6 h-6 justify-center items-center text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent"
                ripple={false}
                onClick={openNavbar}
            >
                  <Bars3Icon className="h-6 w-6" strokeWidth={2}/>
            </IconButton>
          }
          {
            (!!openNav || !!searchOpen) && <IconButton
                variant="text"
                className="flex w-6 h-6 justify-center items-center text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent"
                ripple={false}
                onClick={closeNavbar}
            >
              <XMarkIcon className="h-6 w-6" strokeWidth={2}/>
            </IconButton>
          }
        </span>
      </div>
      <Collapse open={searchOpen} className={'xl:hidden'}>
        <div className="py-4 px-2 md:px-3 lg:px-0 w-full mx-auto flex md:container ">
          <SearchInput isFullWidth={true}/>
        </div>
      </Collapse>
      <Collapse open={openNav} className={'xl:hidden'}>
        <NavList onItemClick={closeNavbar}/>
      </Collapse>
    </MaterialNavbar>
  );
};

export default Navbar;
