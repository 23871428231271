import React, {useEffect, useState, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import MetaComponent from '../../components/common/MetaComponent';
import {fetchArticles} from '../../services/articleService';
import FeaturedCategoryArticles, {
  FeaturedCategoryArticlesSkeleton
} from '../../components/featured-category-articles/FeaturedCategoryArticles';
import ArticlesGrid from '../../components/articles-grid/ArticlesGrid';
import {Typography} from '@material-tailwind/react';
import CategorySection from '../../components/category-section/CategorySection';

export default function Category() {
  const {categorySlug} = useParams();
  const {categories} = useSelector((state) => state.categories);
  const [category, setCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (categories.length) {
      const foundCategory = findCategoryBySlug(categories, categorySlug);

      if (!foundCategory) {
          navigate('/not-found', {replace: true});
          return;
      }

      setCategory(foundCategory);
      setCategoryId(foundCategory.id);
    }
  }, [categorySlug, categories]);

  const findCategoryBySlug = (categories, slug) => {
    for (const category of categories) {
      if (category.slug === slug) {
        return category;
      }

      if (category.children) {
        const childCategory = findCategoryBySlug(category.children, slug);
        if (childCategory) {
          return childCategory;
        }
      }
    }

    return null;
  };

  const metadata = useMemo(() => ({
    title: `${category ? category.name : 'Loading...'} - Balkanski Mozaik`
  }), [category]);

  const featuredCategoryArticles = useMemo(() => {
    if (!categoryId) return <FeaturedCategoryArticlesSkeleton/>;
    return (
      <FeaturedCategoryArticles
        fetchArticles={() => fetchArticles({
          category: categoryId,
          isFeaturedInCategory: true,
          limit: 4
        }).then(data => data.docs)}
      />
    );
  }, [categoryId]);

  const articlesGrid = useMemo(() => {
    if (!category) return null;
    return (
      <ArticlesGrid
        fetchArticles={fetchArticles}
        fetchArticleArgs={{category: categoryId, isFeaturedInCategory: false, limit: 5}}
      />
    );
  }, [categoryId]);

  if (!category) {
    return (
      <div className="pt-6 pb-4">
        <Typography
          as="div"
          variant="h1"
          className="text-2xl sm:text-3xl md:text-4x w-1/3 bg-gray-400 mb-3 rounded-lg animate-pulse"
        >
          &nbsp;
        </Typography>
        <FeaturedCategoryArticlesSkeleton/>
      </div>
    );
  }

  return (
    <>
      <MetaComponent meta={metadata}/>
      <div className="pt-6 pb-4">
         <h1 className={'text-2xl sm:text-3xl md:text-4x mb-3 font-bold'}>{category.name}</h1>
         {featuredCategoryArticles}
         {articlesGrid}
         {!!category.children?.length && category.children.map((childCategory) => (
            <CategorySection key={childCategory.id} title={childCategory.name} titleUrl={`/${childCategory.slug}`}
                             fetchArticles={() => fetchArticles({category: childCategory.id, limit: 5}).then(data => data.docs)}/>
        ))}
      </div>
    </>
  );
}
